import { client } from '@/client';

export type InboxMessage = {
  id: string;
  companyName: string;
  subject: string;
  body?: string;
  footer?: string;
  datetime: number; // UNIX timestamp (seconds)
  attachmentName?: string;
  attachmentPath?: string;
  wasRead: boolean;
  companyLogoUrl: string;
  autodeleteDate: string; // yyyy-MM-dd
  requestSignature: boolean;
};

export type InboxSigners = {
  messageSigned: boolean;
  attachmentHash: string;
  zipCreated: boolean;
  signers: {
    signatureDate?: number;
    ssn: string;
    displayName?: string;
    email: string;
  }[];
};

export type InboxSignResponse =
  | {
      status: 'IN_PROGRESS';
      ref: string;
      url?: string;
      bankId?: {
        autoStartToken: string;
        qrData: string;
      };
    }
  | {
      status: 'COMPLETED';
    }
  | {
      status: 'FAILED';
      ref: string;
      error: string;
    };

export async function deleteInboxMessage(
  messageId: string,
  signal?: AbortSignal,
): Promise<void> {
  return client
    .delete(`/enduser/message/${messageId}`, { signal })
    .then((response) => response.data);
}

export async function forwardInboxMessage(
  messageId: string,
  signal?: AbortSignal,
): Promise<void> {
  return client
    .post(`/enduser/message/${messageId}/forward`, { signal })
    .then((response) => response.data);
}

export async function getInboxMessages(
  signal?: AbortSignal,
): Promise<InboxMessage[]> {
  return client
    .get<InboxMessage[]>('/enduser/message', { signal })
    .then((response) => response.data);
}

export async function getInboxMessageSigners(
  messageId: string,
  signal?: AbortSignal,
): Promise<InboxSigners[]> {
  return client
    .get<InboxSigners[]>(`/enduser/message/${messageId}/signers`, { signal })
    .then((response) => response.data);
}

export async function markInboxMessageAsRead(
  messageId: string,
  signal?: AbortSignal,
): Promise<void> {
  return client
    .post(`/enduser/message/${messageId}/read`, null, { signal })
    .then((response) => response.data);
}

export async function signInboxMessage(
  messageId: string,
  signal?: AbortSignal,
): Promise<Extract<InboxSignResponse, { status: 'IN_PROGRESS' }>> {
  return client
    .post(`/enduser/message/${messageId}/sign`, null, { signal })
    .then((response) => response.data);
}

export async function getSignInboxMessageResult(
  messageId: string,
  ref: string,
  signal?: AbortSignal,
): Promise<InboxSignResponse> {
  return client
    .get(`/enduser/message/${messageId}/result/${ref}`, { signal })
    .then((response) => response.data);
}

export async function getInboxAttachment(
  messageId: string,
  token: string | null,
  signal?: AbortSignal,
): Promise<Blob> {
  return client
    .get<Blob>(`/attachment/enduser/${messageId}`, {
      responseType: 'blob',
      params: {
        token,
      },
      signal,
    })
    .then((response) => response.data);
}

export async function downloadInboxTeleSignPdf(
  messageId: string,
  signal?: AbortSignal,
): Promise<Blob> {
  return client
    .get<Blob>(`/enduser/message/${messageId}/pdf`, {
      responseType: 'blob',
      signal,
    })
    .then((response) => response.data);
}
